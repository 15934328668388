import { useTheme } from '@mui/material/styles';
import {Stack, Chip, ButtonBase, Box, Grid} from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import {Link} from "react-router-dom";
import config from './../../../../config';
import {useSelector} from "react-redux";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {

    const theme = useTheme();
    const { company } = useSelector((state) => state.auth);

    if(company) {
        return (
            <DrawerHeaderStyled theme={theme} open={open}>
                <Stack direction="row" alignItems="center">
                    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: 35,
                                maxWidth: 118,
                            }}
                            alt="Logo company"
                            src={company.logo}
                        />
                    </ButtonBase>
                </Stack>
            </DrawerHeaderStyled>
        );
    }

};

export default DrawerHeader;
