import { Box, useMediaQuery, Divider } from "@mui/material";
import { useState, useEffect } from "react";

// project import
import Profile from "./Profile";
import Conversations from "./Conversations";
import MobileSection from "./MobileSection";
import { LanguageSelector } from "components";
import { AuthApi } from "services";
import { LIST_CONVERSATIONS } from "helpers/accessSubmodule";
import { useSelector } from "react-redux";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const access = useSelector((state) => state.auth.access);
    const authApi = new AuthApi();

    const [submodules, setSubmodule] = useState([]);

    const getAccess = async () => {
        let allowedModule = [];
        let allowedSubmodule = [];
        let response = await authApi.access();
        if (response.success) {
            response.access.forEach((element) => {
                allowedModule.push(element.id);
                element.submodules.forEach((item) => {
                    allowedSubmodule.push(item.id);
                });
            });
            setSubmodule(allowedSubmodule);
        }
    };

    useEffect(() => {
        if (!access.length) {
            getAccess();
        } else {
            setSubmodule(access.submodules);
        }
    }, []);

    return (
        <>
            <Box sx={{ width: "100%", ml: 1 }} />

            {submodules.includes(LIST_CONVERSATIONS) && <Conversations />}

            <Divider
                orientation="vertical"
                flexItem
                sx={{
                    borderColor: "#bbbbbb",
                    marginLeft: "10px",
                    marginRight: "5px",
                }}
            />

            <LanguageSelector />

            {!matchesMd && <Profile />}
            {matchesMd && <MobileSection />}
        </>
    );
};

export default HeaderContent;
