import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeItem } from "redux/slices/MenuSlice";
import { setConversationsCounter } from "redux/slices/HeaderSlice";
import { toast } from "react-toastify";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery,
    Tooltip,
    Link
} from "@mui/material";

// project import
import MainCard from "components/Cards/MainCard";
import Transitions from "components/Transitions";

// assets
import { CloseOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage } from "@fortawesome/pro-solid-svg-icons";

import { CandidateApi } from "services";

// sx styles
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: "1rem",
};

const actionSX = {
    mt: "6px",
    ml: 1,
    top: "auto",
    right: "auto",
    alignSelf: "flex-start",
    transform: "none",
};

const Conversations = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const dispatch = useDispatch();
    const candidateApi = new CandidateApi();

    const iconBackColorOpen = "grey.300";
    const iconBackColor = "grey.100";

    const counter = useSelector((state) => state.header.conversationsCounter);

    const [open, setOpen] = useState(false);
    const [conversations, setConversations] = useState([]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const itemHandler = (id) => {
        dispatch(activeItem({ openItem: [id] }));
    };

    const handleNavigateToMessage = () => {
        navigate("/conversations");
        itemHandler("conversations");
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const getEmployeeConversations = async () => {
        const response = await candidateApi.getMandatsConversationsList();
        if (response.success) {
            setConversations(response.data.conversations);
            dispatch(setConversationsCounter(response.data.total_no_read));
        } else {
            toast.error(t(response.message));
        }
    };

    useEffect(() => {
        getEmployeeConversations();
    }, []);

    if (conversations.length) {
        return (
            <Box sx={{ flexShrink: 0, ml: 0.75, zIndex: 1000 }}>
                <Tooltip title={t("conversations")} placement="bottom">
                    <IconButton
                        disableRipple
                        color="secondary"
                        sx={{
                            color: "text.primary",
                            bgcolor: open ? iconBackColorOpen : iconBackColor,
                        }}
                        aria-label="open conversations"
                        ref={anchorRef}
                        aria-controls={open ? "profile-grow" : undefined}
                        aria-haspopup="true"
                        onClick={
                            counter > 0 ? handleToggle : handleNavigateToMessage
                        }
                    >
                        <Badge
                            badgeContent={counter > 0 ? counter : null}
                            color="error"
                        >
                            <FontAwesomeIcon icon={faMessage} />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Popper
                    placement={matchesXs ? "bottom" : "bottom-end"}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [matchesXs ? -5 : 0, 9],
                                },
                            },
                        ],
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={open} {...TransitionProps}>
                            <Paper
                                sx={{
                                    boxShadow: theme.customShadows.z1,
                                    width: "100%",
                                    minWidth: 285,
                                    maxWidth: 420,
                                    [theme.breakpoints.down("md")]: {
                                        maxWidth: 285,
                                    },
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MainCard
                                        elevation={0}
                                        border={false}
                                        content={false}
                                        secondary={
                                            <IconButton
                                                size="small"
                                                onClick={handleToggle}
                                            >
                                                <CloseOutlined />
                                            </IconButton>
                                        }
                                    >
                                        <List
                                            component="nav"
                                            sx={{
                                                p: 0,
                                                "& .MuiListItemButton-root": {
                                                    py: 0.5,
                                                    "& .MuiAvatar-root":
                                                        avatarSX,
                                                    "& .MuiListItemSecondaryAction-root":
                                                        {
                                                            ...actionSX,
                                                            position:
                                                                "relative",
                                                        },
                                                },
                                                overflowX: "auto",
                                                maxHeight: "300px",
                                            }}
                                        >
                                            {conversations.map(
                                                (item, index) => {
                                                    if (
                                                        item.messages_no_read > 0
                                                    ) {
                                                        return (
                                                            <>
                                                                <ListItemButton component={Link} to="/conversations">
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            sx={{
                                                                                color: "primary.main",
                                                                                bgcolor:
                                                                                    "secondary.lighter",
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faEnvelope
                                                                                }
                                                                            />
                                                                        </Avatar>
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography variant="h6">
                                                                                {
                                                                                    item.manager_name
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <div>
                                                                              <div>{t('client')} : {item.client_name}</div>
                                                                              <div>{t('date')} : {item.message_date}</div>
                                                                            </div>
                                                                          }
                                                                    />
                                                                </ListItemButton>
                                                                <Divider />
                                                            </>
                                                        );
                                                    }
                                                }
                                            )}
                                        </List>

                                        <Divider />

                                        <List
                                            component="nav"
                                            sx={{
                                                p: 0,
                                                "& .MuiListItemButton-root": {
                                                    py: 0.5,
                                                    "& .MuiAvatar-root":
                                                        avatarSX,
                                                    "& .MuiListItemSecondaryAction-root":
                                                        {
                                                            ...actionSX,
                                                            position:
                                                                "relative",
                                                        },
                                                },
                                                overflowX: "auto",
                                                maxHeight: "300px",
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    textAlign: "center",
                                                    py: `${12}px !important`,
                                                }}
                                                onClick={() => {
                                                    handleNavigateToMessage();
                                                    setOpen(false);
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="h6"
                                                            color="primary"
                                                        >
                                                            {t("voirtous")}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </List>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}
                </Popper>
            </Box>
        );
    } else {
        return <></>;
    }
};

export default Conversations;
