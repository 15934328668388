import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "redux/slices/AuthSlice";
import { clearMenu } from "redux/slices/MenuSlice";
import { clearHeader } from "redux/slices/HeaderSlice";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";

// project import
import MainCard from "components/Cards/MainCard";
import Transitions from "components/Transitions";
import ProfileTab from "./ProfileTab";
import { AuthApi } from "services";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`,
        "aria-controls": `profile-tabpanel-${index}`,
    };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const { user } = useSelector((state) => state.auth);
    const theme = useTheme();
    const dispatch = useDispatch();
    const matchesMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const authApi = new AuthApi();

    const handleLogout = async () => {
        let response = await authApi.logout();
        dispatch(clearAuth());
        dispatch(clearMenu());
        dispatch(clearHeader());
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const iconBackColorOpen = "grey.300";

    if (user) {
        return (
            <>
                {!matchesMd && (
                    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                        <ButtonBase
                            sx={{
                                p: 0.25,
                                bgcolor: open
                                    ? iconBackColorOpen
                                    : "transparent",
                                borderRadius: 1,
                                "&:hover": { bgcolor: "secondary.lighter" },
                            }}
                            aria-label="open profile"
                            ref={anchorRef}
                            aria-controls={open ? "profile-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                sx={{ p: 0.5 }}
                            >
                                <Avatar
                                    alt="profile user"
                                    src={user.avatar}
                                    sx={{ width: 32, height: 32 }}
                                    variant="rounded"
                                />
                                <Typography variant="subtitle1">
                                    {user.firstName + " " + user.lastName}
                                </Typography>
                            </Stack>
                        </ButtonBase>

                        <Popper
                            placement="bottom-end"
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            popperOptions={{
                                modifiers: [
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [0, 9],
                                        },
                                    },
                                ],
                            }}
                            sx={{
                                zIndex: 1250,
                            }}
                        >
                            {({ TransitionProps }) => (
                                <Transitions
                                    type="fade"
                                    in={open}
                                    {...TransitionProps}
                                >
                                    {open && (
                                        <Paper
                                            sx={{
                                                boxShadow:
                                                    theme.customShadows.z1,
                                            }}
                                        >
                                            <ClickAwayListener
                                                onClickAway={handleClose}
                                            >
                                                <MainCard
                                                    elevation={0}
                                                    border={false}
                                                    content={false}
                                                >
                                                    {open && (
                                                        <>
                                                            <TabPanel
                                                                value={0}
                                                                index={0}
                                                                dir={
                                                                    theme.direction
                                                                }
                                                            >
                                                                <ProfileTab
                                                                    handleLogout={
                                                                        handleLogout
                                                                    }
                                                                />
                                                            </TabPanel>
                                                        </>
                                                    )}
                                                </MainCard>
                                            </ClickAwayListener>
                                        </Paper>
                                    )}
                                </Transitions>
                            )}
                        </Popper>
                    </Box>
                )}

                {matchesMd && (
                    <TabPanel value={0} index={0} dir={theme.direction}>
                        <ProfileTab handleLogout={handleLogout} />
                    </TabPanel>
                )}
            </>
        );
    }
};

export default Profile;
