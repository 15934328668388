import {
    faUser,
    faBriefcase,
    faBullseyeArrow,
    faCalendar,
    faCalendarMinus,
    faChalkboardTeacher,
    faClipboardList,
    faFolder,
} from "@fortawesome/pro-duotone-svg-icons";
import {
    EVALUATIONS,
    INTERVIEW,
    JOB,
    FORMS,
    DOCUMENTS,
    FORMATIONS,
    CANDIDATURE,
    PROFILE,
    INTERNAL_JOB
} from "helpers/accessModule";

const menuItems = {
    items: [
        {
            id: "group-items",
            title: "",
            type: "group",
            children: [
                {
                    id: "profil",
                    title: "profil",
                    type: "item",
                    url: "/profile",
                    icon: faUser,
                    breadcrumbs: false,
                    access: PROFILE
                },
                {
                    id: "interviews",
                    title: "entretiens",
                    type: "item",
                    url: "/interviews",
                    icon: faCalendar,
                    breadcrumbs: false,
                    access: INTERVIEW
                },
                {
                    id: "internaljobs",
                    title: "postesinternes",
                    type: "item",
                    url: "/internal-job",
                    icon: faCalendarMinus,
                    breadcrumbs: false,
                    access: INTERNAL_JOB
                },
                {
                    id: "jobs",
                    title: "emplois",
                    type: "item",
                    url: "/jobs",
                    icon: faCalendarMinus,
                    breadcrumbs: false,
                    access: JOB
                },
                {
                    id: "forms",
                    title: "formulaires",
                    type: "item",
                    url: "/forms",
                    icon: faClipboardList,
                    breadcrumbs: false,
                    access: FORMS
                },
                {
                    id: "documents",
                    title: "documents",
                    type: "item",
                    url: "/documents",
                    icon: faFolder,
                    breadcrumbs: false,
                    access: DOCUMENTS
                },
                {
                    id: "candidatures",
                    title: "candidatures",
                    type: "item",
                    url: "/candidatures",
                    icon: faBriefcase,
                    breadcrumbs: false,
                    access: CANDIDATURE
                },
                {
                    id: "evaluations",
                    title: "evaluations",
                    type: "item",
                    url: "/evaluations",
                    icon: faBullseyeArrow,
                    breadcrumbs: false,
                    access: EVALUATIONS
                },
                {
                    id: "formations",
                    title: "formations",
                    type: "item",
                    url: "/formations",
                    icon: faChalkboardTeacher,
                    breadcrumbs: false,
                    access: FORMATIONS
                },
            ],
        },
    ],
};

export default menuItems;
